import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: true },
    { key: "company", sortable: true },
    { key: "email", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const isSearchLoading = ref(false);
  const organizationSelect = ref(null);
  const isSearch = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      isSearch,
      roleFilter,
      planFilter,
      statusFilter,
      organizationSelect,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (organizationSelect.value !== null) {
      const organizationData = {
        company_id: organizationSelect.value.id,
        role_name: userData.role_name,
        currentPage: currentPage.value,
        perPage: perPage.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value,
      };
      store.commit("appConfig/SPINNER_CONFIG", true);
      store
        .dispatch("app-user/fetchUsersByOrganizationId", organizationData)
        .then((response) => {
          store.commit("appConfig/SPINNER_CONFIG", false);
          const { items, total } = response.data;

          callback(items);
          totalUsers.value = total;
        })
        .catch(() => {
          store.commit("appConfig/SPINNER_CONFIG", false);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching users list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    } else {
      const organizationData = {
        company_id: userData.company_id,
        role_name: userData.role_name,
        currentPage: currentPage.value,
        perPage: perPage.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value,
        region:userData.default_region_id,
      };
      const searchData = {
        company_id: userData.company_id,
        searchQuery: searchQuery.value,
        perPage: perPage.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value,
        region:userData.default_region_id,
      };

      if (
        userData.role_name == "GLOBAL_ADMIN" ||
        userData.role_name == "ACCOUNT_MANAGER"
      ) {
        if (searchQuery.value == "" && isSearch.value == false) {
          if (userData.role_name == "ACCOUNT_MANAGER") {
            organizationData.orgs = userData.orgs_list.toString();
          }
          store.commit("appConfig/SPINNER_CONFIG", true);
          store
            .dispatch("app-user/fetchAllUser", organizationData)
            .then((response) => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              const { items, total } = response.data;
              callback(items);
              totalUsers.value = total;
            })
            .catch(() => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching users list",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          if (userData.role_name == "ACCOUNT_MANAGER") {
            searchData.orgs = userData.orgs_list.toString();
          }
          isSearchLoading.value = true;
          store.commit("appConfig/SPINNER_CONFIG", true);
          store
            .dispatch("app-user/fetchUsersBySearchQuery", searchData)
            .then((response) => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              isSearchLoading.value = false;
              isSearch.value = false;
              const { items, total } = response.data;

              callback(items);
              totalUsers.value = total;
            })
            .catch(() => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              isSearchLoading.value = false;
              isSearch.value = false;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error user is not available in users list",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      } else {
        if (searchQuery.value == "" && isSearch.value == false) {
          store.commit("appConfig/SPINNER_CONFIG", true);
          store
            .dispatch("app-user/fetchUsersByOrganizationId", organizationData)
            .then((response) => {
              if(response){
              store.commit("appConfig/SPINNER_CONFIG", false);
              const { items, total } = response.data;

              callback(items);
              totalUsers.value = total;
              }
            })
            .catch(() => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching users list",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          isSearchLoading.value = true;
          store.commit("appConfig/SPINNER_CONFIG", true);
          store
            .dispatch("app-user/fetchUsersBySearchQuery", searchData)
            .then((response) => {
              if(response){
              store.commit("appConfig/SPINNER_CONFIG", false);
              isSearchLoading.value = false;
              isSearch.value = false;
              const { items, total } = response.data;

              callback(items);
              totalUsers.value = total;
              }
            })
            .catch(() => {
              store.commit("appConfig/SPINNER_CONFIG", false);
              isSearchLoading.value = false;
              isSearch.value = false;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error user is not available in users list",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    isSearchLoading,
    organizationSelect,
    isSearch,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
