<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role="role"
      :organization="organization"
      :selectedDomainName="selectedDomainName"
      :domainNames="domainNames"
      @refetch-data="refetchData"
      @domain="domain"
    />

    <!-- Table Container Card -->
    <b-card no-body class="user-filter">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" lg="5" md="12" sm="12" xs="12">
            <b-row>
              <b-col
                class="col-auto d-flex align-items-center justify-content-start mb-1 mb-md-0 p-1 px-md-1 p-md-0"
              >
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                />
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="6"
                xs="12"
                v-if="
                  userData.role_name == 'GLOBAL_ADMIN' ||
                  userData.role_name == 'ACCOUNT_MANAGER'
                "
                class="p-1 px-md-1 p-md-0"
              >
                <v-select
                  v-model="organizationSelect"
                  :options="organization"
                  placeholder="Select Organization"
                  label="name"
                  :disabled="searchQuery !== ''"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col cols="12" lg="7" md="12" sm="12" xs="12">
            <b-row class="d-flex justify-content-end">
              <b-col lg="4" md="4" sm="4" xs="12" class="p-1 px-md-1 p-md-0">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  :disabled="organizationSelect !== null"
                />
              </b-col>
              <b-col class="p-1 px-md-1 p-md-0 pl-md-0 col-auto">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="isSearch = true"
                  :disabled="organizationSelect !== null"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <div class="users">
        <b-table
          v-show="!isSearchLoading"
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="viewUsers"
        >
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.first_name + data.item.last_name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{
                    name: 'apps-users-view',
                    params: { id: data.item.uid },
                  }"
                />
              </template>
              <span v-if="data.item.first_name">
                {{ data.item.first_name + " " + data.item.last_name }}
              </span>
            </b-media>
          </template>

          <!-- Column: company -->
          <template #cell(company)="data">
            {{ data.item.company_name }}
          </template>

          <!-- Column: email -->
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              @click="
                (e) => {
                  e.stopPropagation();
                  Edit(data);
                }
              "
              size="20"
              class="feather-info feather"
            />
            <feather-icon
              icon="TrashIcon"
              @click="
                (e) => {
                  e.stopPropagation();
                  showModal(data.item);
                }
              "
              size="20"
              class="feather-danger feather"
              v-if="userData.role_name !== 'ACCOUNT_MANAGER'"
            />
          </template>
        </b-table>
      </div>

      <b-modal
        ref="my-modal"
        no-close-on-backdrop
        hide-footer
        title="Confirmation"
      >
        <div class="d-block text-center" v-if="activeItem">
          <h4 class="mb-0">
            Are you sure you want to delete {{ activeItem.display_name }}
          </h4>
        </div>
        <div class="d-flex justify-content-end" v-if="activeItem">
          <b-button
            class="mt-1 mr-1"
            variant="primary"
            @click="
              (e) => {
                e.stopPropagation();
                deleteItem(activeItem);
                hideModal();
              }
            "
            >Ok</b-button
          >
          <b-button class="mt-1" @click="hideModal">Cancel</b-button>
        </div>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              limit="3"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watchEffect } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import organizationStoreModule from "../../organization/organizationStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,

    vSelect,
  },
  data() {
    return {
      activeItem: null,
    };
  },
  watch: {
    searchQuery(newValue) {
      if (newValue == "") {
        this.refetchData();
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.organizationSelect = null;
        this.searchQuery = "";
        this.currentPage = 1;
        this.refetchData();
      },
      immediate: false,
    },
  },
  methods: {
    activate(id) {
      this.perPage = id;
    },
    showModal(item) {
      this.activeItem = item;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    Detail(data) {
      this.$router.push({
        name: "apps-users-view",
        params: { id: data.item.uid },
      });
    },
    Edit(data) {
      this.$router.push({
        name: "apps-users-edit",
        params: { id: data.item.uid },
      });
    },
    viewUsers(item) {
      this.$router.push("/apps/users/view/" + item.uid);
    },
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    let userData = JSON.parse(localStorage.getItem("userData"));

    const toast = useToast();
    let role = ref([]);
    let organization = ref([]);
    let domainNames = ref([]);
    let selectedDomainName = ref("");

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      isSearchLoading,
      organizationSelect,
      isSearch,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const getOrganizationsForRole = () => {
      if (userData.role_name == "GLOBAL_ADMIN") {
        const data = {
          region_id: userData.default_region_id,
        };
        store
          .dispatch("app-user/fetchOrganization", data)
          .then((response) => {
            organization.value = response.data.items;
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching organizations",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }

      if (userData.role_name == "ACCOUNT_MANAGER") {
        const data = {
          id: userData.id,
          region_id: userData.default_region_id,
        };
        store
          .dispatch("app-user/fetchOrganizationByAccountManager", data)
          .then((response) => {
            organization.value = response.data;
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching organizations",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    };

    if (
      userData.role_name !== "GLOBAL_ADMIN" &&
      userData.role_name !== "ACCOUNT_MANAGER"
    ) {
      const domainData = {
        company_id: userData.company_id,
      };

      store
        .dispatch("app-user/fetchDomainNames", domainData)
        .then((response) => {
          domainNames.value = response.data.name;
          selectedDomainName.value = response.data.name[0];
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching domain names",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });

      store
        .dispatch("app-user/fetchRole", domainData)
        .then((response) => {
          role.value = response.data.roles;
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching role",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }

    const domain = (value) => {
      const domainData = {
        company_id: value,
      };

      store
        .dispatch("app-user/fetchDomainNames", domainData)
        .then((response) => {
          domainNames.value = response.data.name;
          selectedDomainName.value = response.data.name[0];
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching domain names",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });

      store
        .dispatch("app-user/fetchRole", domainData)
        .then((response) => {
          role.value = response.data.roles;
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching role",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const deleteItem = (item) => {
      if (item) {
        store
          .dispatch("app-user/deleteUser", item.uid)
          .then(() => {
            refetchData();
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `User deleted successfully`,
              },
            });
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: `User could not deleted`,
              },
            });
          });
      }
    };

    watchEffect(async () => {
      if (store.state.app.userData.default_region_id) {
        userData = JSON.parse(localStorage.getItem("userData"));
        getOrganizationsForRole();
      }
    });

    const isAddNewUserSidebarActive = ref(false);

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      getOrganizationsForRole,
      userData,

      role,
      organization,
      domainNames,
      selectedDomainName,
      domain,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      isSearchLoading,
      organizationSelect,
      isSearch,
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.media-body {
  margin-top: 0.4rem;
}
.feather {
  cursor: pointer;
  margin: 0 1rem 0 0rem;
}

.user-filter {
  margin-bottom: 2rem;
}

.user-filter {
  margin-bottom: 2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.users table tr {
  cursor: pointer;
}
</style>
