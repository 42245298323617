<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New User</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="first name"
            rules="required"
          >
            <b-form-group label-for="first-name">
              <template v-slot:label>
                First Name <span class="required">*</span>
              </template>
              <b-form-input
                id="first-name"
                v-model="userData.first_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last name -->
          <validation-provider
            #default="validationContext"
            name="last name"
            rules="required"
          >
            <b-form-group label-for="last-name">
              <template v-slot:label>
                Last Name <span class="required">*</span>
              </template>
              <b-form-input
                id="last-name"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Organization Name -->
          <validation-provider
            #default="validationContext"
            name="organization name"
            rules="required"
            v-if="
              userData1.role_name == 'GLOBAL_ADMIN' ||
              userData1.role_name == 'ACCOUNT_MANAGER'
            "
          >
            <b-form-group label-for="organization-name">
              <template v-slot:label>
                Organization Name <span class="required">*</span>
              </template>
              <b-form-select
                id="organization-name"
                v-model="userData.company"
                :options="organization"
                :state="getValidationState(validationContext)"
                value-field="id"
                text-field="name"
                @change="selectDomain"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <b-row>
            <b-col class="pr-0">
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group label-for="email">
                  <template v-slot:label>
                    Email <span class="required">*</span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="email_username"
                    autocomplete="off"
                    :state="getValidationState(validationContext) && domainName"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="domainName">
                    This email is part of a reserved domain. Please enter a
                    different email address.
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="domain-style pl-0">
              <validation-provider
                #default="validationContext"
                name="domain name"
                rules="required"
              >
                <b-input-group prepend="@">
                  <b-form-select
                    autocomplete="off"
                    v-b-tooltip.hover
                    title="Please select organization"
                    v-if="userData.company == ''"
                    v-model="selectedDomainName"
                    :options="domainNames"
                    :state="
                      getValidationState(validationContext) && organizationData
                    "
                  ></b-form-select>
                  <b-form-select
                    v-if="userData.company != ''"
                    v-model="selectedDomainName"
                    :options="domainNames"
                    :state="
                      getValidationState(validationContext) && organizationData
                    "
                  ></b-form-select>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :state="organizationData"
                  v-if="userData.company != null"
                >
                  There is no domain name available, Please contact Admin.
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Role -->
          <validation-provider
            #default="validationContext"
            name="role"
            rules="required"
          >
            <b-form-group label-for="role">
              <template v-slot:label>
                Role <span class="required">*</span>
              </template>
              <b-form-select
                v-b-tooltip.hover
                title="Please select organization"
                v-if="
                  userData.company == '' &&
                  userData1.role_name == 'GLOBAL_ADMIN'
                "
                v-model="userData.role_id"
                :options="role"
                :state="getValidationState(validationContext)"
              ></b-form-select>

              <b-form-select
                id="role"
                v-if="
                  userData.company !== '' &&
                  userData1.role_name == 'GLOBAL_ADMIN'
                "
                v-model="userData.role_id"
                :options="role"
                :state="getValidationState(validationContext)"
                value-field="role_id"
                text-field="role_name"
              ></b-form-select>

              <b-form-select
                id="role"
                v-if="userData1.role_name !== 'GLOBAL_ADMIN'"
                v-model="userData.role_id"
                :options="role"
                :state="getValidationState(validationContext)"
                value-field="role_id"
                text-field="role_name"
              ></b-form-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- passwod -->
          <validation-provider
            #default="validationContext"
            name="password"
            rules="required|min:6"
            vid="password"
          >
            <b-form-group label-for="password">
              <template v-slot:label>
                Password <span class="required">*</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :state="getValidationState(validationContext)"
                  :type="passwordFieldType"
                  trim
                  autocomplete="off"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- confirm passwod -->
          <validation-provider
            #default="validationContext"
            name="confirm password"
            rules="required|confirmed:password"
          >
            <b-form-group label-for="confirm-password">
              <template v-slot:label>
                Confirm Password <span class="required">*</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="confirm-password"
                  :type="confirmPasswordFieldType"
                  v-model="userData.confirm_password"
                  :state="getValidationState(validationContext)"
                  trim
                  autocomplete="off"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmpasswordToggleIcon"
                    @click="toggleConfirmPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Display name -->
          <validation-provider
            #default="validationContext"
            name="display name"
            rules="required"
          >
            <b-form-group label-for="display-name">
              <template v-slot:label>
                Display Name <span class="required">*</span>
              </template>
              <b-form-input
                id="display-name"
                v-model="userData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="
                userData.first_name == '' ||
                userData.last_name == '' ||
                email_username == '' ||
                userData.role_id == '' ||
                userData.password == '' ||
                userData.confirm_password == '' ||
                userData.display_name == '' ||
                isLoading
              "
            >
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  label="Text Centered"
                  v-if="isLoading"
                ></b-spinner>
                <span class="button-title">Add</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BLink,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, confirmed, min } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
// import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
// import { string } from "prop-types";
export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BLink,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    role: {
      type: Array,
      required: true,
    },
    organization: {
      required: true,
    },
    domainNames: {
      required: true,
    },
    selectedDomainName: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDomainName(newValue) {
      this.selectedDomainName = newValue; // Update when the prop changes
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      // countries,
      togglePassword: false,
      confirmed,
      min,
      userData1: JSON.parse(localStorage.getItem("userData")),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmpasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    organizationNames() {
      return store.getters["app-organization/organizationsList"];
    },
    domainName() {
      return this.email_username.includes("@") ? false : true;
    },
    organizationData(props) {
      return props.selectedDomainName == null ? false : true;
    },
  },
  methods: {},
  setup(props, { emit }) {
    const toast = useToast();
    let isLoading = ref(false);
    let email_username = ref("");
    const currentUser = JSON.parse(localStorage.getItem("userData"));

    const blankUserData = {
      first_name: "",
      last_name: "",
      email: "",
      role_id: "",
      company: "",
      company_name: "",
      display_name: "",
      password: "",
      confirm_password: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      isLoading.value = true;
      if (
        currentUser.role_name !== "GLOBAL_ADMIN" &&
        currentUser.role_name !== "ACCOUNT_MANAGER"
      ) {
        userData.value.company = currentUser.company_id;
      }
      userData.value.email =
        email_username.value + "@" + props.selectedDomainName;
      store
        .dispatch("app-user/addUser", userData.value)
        .then(() => {
          isLoading.value = false;
          emit("refetch-data");
          emit("update:is-add-new-user-sidebar-active", false);
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `User added successfully`,
            },
          });
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `User could not added`,
            },
          });
        });
    };

    const selectDomain = (value) => {
      emit("domain", value);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      isLoading,
      userData,
      email_username,
      onSubmit,
      selectDomain,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/variables";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.required {
  color: $required;
  font-size: 1rem;
}
.domain-style {
  margin-top: 1.7rem !important;
}
.btn-secondary {
  background-color: $white !important;
  color: $black !important;
  border-color: $black !important;
}
</style>
